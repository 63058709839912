import "./Footer.css";
import logo from "../../../assets/medical/footer/main_logo_text_white.png";
import facebookLogo from "../../../assets/medical/footer/facebook.png";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="outer-div">
      <footer className="footer-container">
        <div className=" footer-grid grid grid--4-cols">
          <div className="logo-col">
            <a href="/" className="footer-logo">
              <img
                src={logo}
                alt="balintanalitika logo"
                className="footer_logo_img"
              />
            </a>
            <ul className="social-links">
              <li>
                <a
                  href="https://www.facebook.com/orvosdiagnosztika"
                  className="footer-link "
                  aria-label="balintanalitika facebook oldal"
                >
                  <img
                    src={facebookLogo}
                    className="facebook-logo"
                    alt="facebook logó"
                  />
                </a>
              </li>
            </ul>
            {/* <p className="copyright">
            &copy; {year} by Bálint Analitika.
            <br />
            Minden jog fenntartva. <br />A weboldalon található képek, ikonok a{" "}
            <a href="https://www.freepik.com" target="_blank" rel="noreferrer">
              Freepik
            </a>
            ,{" "}
            <a href="https://www.unsplash.com" target="_blank" rel="noreferrer">
              Unsplash
            </a>{" "}
            és a{" "}
            <a href="https://www.flaticon.com" target="_blank" rel="noreferrer">
              Flaticon
            </a>{" "}
            oldalakról származnak.
          </p> */}
          </div>
          <div className="address-col">
            <p className="footer-heading">Elérhetőségeink</p>
            <address className="contacts">
              <div className="contact-div">
                <p className="address">
                  Központ <br />
                  1116 Budapest, <br />
                  Kondorfa utca 6.
                </p>
                <p className="customer-service">
                  Orvosdiagnosztika
                  <br />
                  <span>Mobil: </span>
                  <a className="footer-link" href="tel:+36202542591">
                    +36 20 254 2591
                  </a>
                  <br />
                  <span>Vezetékes: </span>
                  <a className="footer-link" href="tel:+3612053044">
                    +36 1 205 3044
                  </a>
                  <br />
                  <a
                    className="footer-link"
                    href="mailto:orvosdiagnosztika@balintanalitika.hu"
                  >
                    orvosdiagnosztika@balintanalitika.hu
                  </a>
                </p>
              </div>
            </address>
          </div>
          <nav className="nav-col">
            <p className="footer-heading">Navigáció</p>
            <ul className="footer-nav">
              <li>
                <a className="footer-link" href="/orvosdiagnosztika">
                  Főoldal
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="/orvosdiagnosztika/rolunk/tortenetunk"
                >
                  Rólunk
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="/orvosdiagnosztika/konzultacio"
                >
                  Konzultáció
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="/orvosdiagnosztika/vizsgalatok"
                >
                  Vizsgálatok
                </a>
              </li>
              <li>
                <a className="footer-link" href="/orvosdiagnosztika/araink">
                  Áraink
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="/orvosdiagnosztika/idopontfoglalas"
                >
                  Időpontfoglalás
                </a>
              </li>
              <li>
                <a className="footer-link" href="/orvosdiagnosztika/kapcsolat">
                  Kapcsolat
                </a>
              </li>
              <li>
                <a className="footer-link" href="/oldalterkep">
                  Oldaltérkép
                </a>
              </li>
            </ul>
          </nav>
          <nav className="docs-col">
            <p className="footer-heading">Fontos dokumentumok</p>
            <ul className="footer-nav">
              <li>
                <a
                  className="footer-link"
                  href="/assets/balint_analitika_adatvedelmi_tajekoztato_181001.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Adatvédelmi tájékoztató
                </a>
              </li>
              <li>
                <a className="footer-link" href="/impresszum">
                  Impresszum
                </a>
              </li>
              <li>
                <p className="footer-perm-number">
                  <strong>
                    Engedélyszámunk:
                    <br /> Bp-11/NEO/4385-4/2023.
                  </strong>
                </p>
              </li>
            </ul>
          </nav>
        </div>
        <div className="copyright-row">
          <p className="copyright">
            &copy; {year} by Bálint Analitika. Minden jog fenntartva. A
            weboldalon található képek, ikonok a{" "}
            <a href="https://www.freepik.com" target="_blank" rel="noreferrer">
              Freepik
            </a>
            ,{" "}
            <a href="https://www.unsplash.com" target="_blank" rel="noreferrer">
              Unsplash
            </a>{" "}
            és a{" "}
            <a href="https://www.flaticon.com" target="_blank" rel="noreferrer">
              Flaticon
            </a>{" "}
            oldalakról származnak.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
