export const chemicalPriceOfferContacts = [
  {
    id: 1,
    name: "Nagy Marianna",
    email: "elelmiszer@balintanalitika.hu",
    phone: "06-1/206-0732 /116 mellék",
    mobile: "06-20/279-6250",
    field: "Élelmiszer- és takarmányvizsgálatok",
    fieldShort: "Élelmiszer",
  },
  {
    id: 2,
    name: "Merka Máriusz",
    email: "levego@balintanalitika.hu",
    phone: "06-1/206-0732 /213, /252 mellék",
    mobile: "06-30/954-4516",
    field: "Levegővizsgálatok",
    fieldShort: "Levegő",
  },
  {
    id: 3,
    name: "Gubányi Zsófia",
    email: "kornyezet@balintanalitika.hu",
    phone: "06-1/206-0732 /205 mellék",
    mobile: "06-20/421-4119",
    field: "Környezetanalitika és általános árajánlatkérés",
    extraContent: "(víz, talaj, hulladék, mintavétel, egyéb)",
    fieldShort: "Árajánlatkérés",
  },
];
