import React from "react";
import { Link } from "react-router-dom";
import "./Sitemap.css";
import { sortedExaminationsGroupArray } from "../Medical/Examinations/examCategories";

// Generate.sitemap.js file contains the following code snippet as well, so if you modify this part, make sure to update the other file as well:
const medicalExamPackageUrls = [
  { url: "/orvosdiagnosztika/vizsgalatok/csomagok/szures", label: "Szűrés" },
  {
    url: "/orvosdiagnosztika/vizsgalatok/csomagok/terhesseg",
    label: "Terhesség",
  },
  {
    url: "/orvosdiagnosztika/vizsgalatok/csomagok/meddoseg",
    label: "Meddőség",
  },
  { url: "/orvosdiagnosztika/vizsgalatok/csomagok/torch", label: "TORCH" },
  {
    url: "/orvosdiagnosztika/vizsgalatok/csomagok/nemikorokozokkimutatasa",
    label: "Nemi kórokozók kimutatása",
  },
  {
    url: "/orvosdiagnosztika/vizsgalatok/csomagok/mutetelott",
    label: "Műtét előtt",
  },
];
const sitemapLinks = {
  main: [{ url: "/", label: "Főoldal" }],
  chemical: {
    about: [
      { url: "/kemiaianalitika/rolunk/tortenetunk", label: "Történetünk" },
      { url: "/kemiaianalitika/rolunk/csapatunk", label: "Csapatunk" },
      { url: "/kemiaianalitika/rolunk/partnereink", label: "Partnereink" },
      {
        url: "/kemiaianalitika/rolunk/minosegiranyitas",
        label: "Minőségirányítás",
      },
      { url: "/kemiaianalitika/rolunk/dokumentumok", label: "Dokumentumok" },
      { url: "/kemiaianalitika/rolunk/projektek", label: "Projektek" },
    ],
    services: [
      {
        url: "/kemiaianalitika/vizsgalatok/levego",
        label: "Levegő vizsgálatok",
      },
      {
        url: "/kemiaianalitika/vizsgalatok/elelmiszer",
        label: "Élelmiszer vizsgálatok",
      },
      { url: "/kemiaianalitika/vizsgalatok/mintavetel", label: "Mintavétel" },
    ],
    chemicalSampleRecieve: [
      { url: "/kemiaianalitika/mintafogadas", label: "Mintafogadás" },
    ],
    priceOfferRequest: [
      {
        url: "/kemiaianalitika/arajanlatkeres",
        label: "Árajánlatkérés",
      },
    ],
    contactAndCareer: [
      { url: "/kemiaianalitika/kapcsolat", label: "Kapcsolat" },
      { url: "/kemiaianalitika/karrier", label: "Karrier" },
    ],
  },
  medical: {
    about: [
      { url: "/orvosdiagnosztika/rolunk/tortenetunk", label: "Történetünk" },
      { url: "/orvosdiagnosztika/rolunk/csapatunk", label: "Csapatunk" },
      { url: "/orvosdiagnosztika/rolunk/partnereink", label: "Partnereink" },
      { url: "/orvosdiagnosztika/rolunk/karrier", label: "Karrier" },
    ],
    services: [
      { url: "/orvosdiagnosztika/vizsgalatok", label: "Vizsgálatok" },
      {
        url: "/orvosdiagnosztika/vizsgalatok/cegeknek",
        label: "Vizsgálatok cégeknek",
      },
      {
        label: "Vizsgálatok kategóriánként",
        children: sortedExaminationsGroupArray.map((item) => ({
          url: item.linkSrc,
          label: item.name,
        })),
      },
      {
        label: "Vizsgálati csomagok",
        children: medicalExamPackageUrls,
      },
    ],
    consultation: [
      { url: "/orvosdiagnosztika/konzultacio", label: "Konzultáció" },
    ],
    prices: [{ url: "/orvosdiagnosztika/araink", label: "Áraink" }],
    appointment: [
      { url: "/orvosdiagnosztika/idopontfoglalas", label: "Időpontfoglalás" },
    ],
    contact: [{ url: "/orvosdiagnosztika/kapcsolat", label: "Kapcsolat" }],
  },
  legal: [
    { url: "/impresszum", label: "Impresszum" },
    {
      url: "/assets/balint_analitika_adatvedelmi_tajekoztato_181001.pdf",
      label: "Adatvédelmi Tájékoztató",
    },
  ],
};

const Sitemap = () => {
  return (
    <div className="container sitemap-container navbar-margin">
      <h1 className="heading-primary">Oldaltérkép</h1>
      <ul className="sitemap-list">
        <li>
          <h2>Főoldal</h2>
          <ul>
            {sitemapLinks.main.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <h2>Kémiai analitika</h2>
          <h3>Rólunk</h3>
          <ul>
            {sitemapLinks.chemical.about.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Szolgáltatások</h3>
          <ul>
            {sitemapLinks.chemical.services.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Mintafogadás</h3>
          <ul>
            {sitemapLinks.chemical.chemicalSampleRecieve.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Árajánlatkérés</h3>
          <ul>
            {sitemapLinks.chemical.priceOfferRequest.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Kapcsolat és karrier</h3>
          <ul>
            {sitemapLinks.chemical.contactAndCareer.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <h2>Orvosdiagnosztika</h2>
          <h3>Rólunk</h3>
          <ul>
            {sitemapLinks.medical.about.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Szolgáltatások</h3>
          <ul>
            {sitemapLinks.medical.services.map((link, index) => (
              <li key={index} className={!link.url ? "no-url" : ""}>
                {link.url ? (
                  <Link to={link.url}>{link.label}</Link>
                ) : (
                  <span>{link.label}</span>
                )}
                {link.children && (
                  <ul>
                    {link.children.map((child, childIndex) => (
                      <li
                        key={childIndex}
                        className={!child.url ? "no-url" : ""}
                      >
                        {child.url ? (
                          <Link to={child.url}>{child.label}</Link>
                        ) : (
                          <span>{child.label}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <h3>Konzultáció</h3>
          <ul>
            {sitemapLinks.medical.consultation.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Áraink</h3>
          <ul>
            {sitemapLinks.medical.prices.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Időpontfoglalás</h3>
          <ul>
            {sitemapLinks.medical.appointment.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
          <h3>Kapcsolat</h3>
          <ul>
            {sitemapLinks.medical.contact.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <h2>Fontos</h2>
          <ul>
            {sitemapLinks.legal.map((link, index) => (
              <li key={index}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Sitemap;
