import React from "react";
import "./chemicalNews.css";
import "./chemicalServices.css";
import "./ChemicalNewsSimpleLayout.css";
import { chemicalNewsItems } from "./chemicalNewsItems";

const ChemicalNewsSimpleLayout = () => {
  return (
    <>
      <div className="container services-container">
        <h1 className="heading-primary">Hírek</h1>
        <div className="grid examinations-card-grid grid--3-cols grid--center-v" id="home-news-card-grid">
          {[...chemicalNewsItems].reverse().map((item) => (
            <div className="col-sm-3" key={item.id}>
              <div className="card card-with-shadow  border-usual">
                <img src={item.picLink} className="card-img-top " alt="..." />
                <div className="card-body">
                  <div className="text-col col-sm-12 news-card-text-col">
                    <h4 className="news-card-title">{item.title}</h4>
                    <p className="news-card-text">{item.date}</p>
                  </div>
                  <div className="card-footer">
                    <a
                      href={item.contentLink}
                      className="btn btn-lg examinations-info-button btn--full"
                    >
                      Olvasás
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default ChemicalNewsSimpleLayout;
