import "./chemicalHome.css";
import ChemicalServices from "./ChemicalServices";
import ChemicalHomeHero from "./ChemicalHomeHero";
// import ChemicalNews from "./ChemicalNews";
import ChemicalNewsSimpleLayout from "./ChemicalNewsSimpleLayout";

const ChemicalHome = () => {
  return (
    <>
      <ChemicalHomeHero />
      <ChemicalServices />
      {/* <ChemicalNews /> */}
      <ChemicalNewsSimpleLayout />
    </>
  );
};

export default ChemicalHome;
